.qr-scanner video {
    width: 100%;
    height: 100vh !important;
    object-fit: cover;
}

.qr-scanner div > div {
    width: 100%;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.qr-scanner div > div > svg {
    border: none !important;
    padding: 35px;
}

.qr-scanner div > div > svg > path {
    stroke: #000;
}